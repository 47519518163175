<template>
  <main class="container mt-5 py-2">
    <div class="row justify-content-center mb-5">
      <div>
        <div class="card-register-2 p-2">
          <div class="d-flex justify-content-center">
            <img src="/images/logo.jpg" alt="" width="200" style="display: block; margin: auto;" />
          </div>
          <div class="mt-1">
            <p class="parrafo-register mb-0">¡Crea tu cuenta y gana $25.000<br/>en tu primera recarga!</p>          
          </div>
          <div class="card-body">
            <form>     
              <div class="form-group row justify-content-center">
                <div class="col-md-10 col-12">
                  <input
                    v-model="nombre"
                    type="text"
                    class="form-control p-3 border"
                    placeholder="Nombre de contacto"
                    name="nom"
                    maxlength="60"
                    id="nom"
                    @change="isEmptyNameContact"
                  />
                </div>
              </div>

              <div class="form-group row justify-content-center">
                <div class="col-md-10 col-12"> 
                  <input  
                    v-model="telefono"
                    type="number" 
                    class="form-control p-3 border"
                    placeholder="Número de celular Colombia"
                    name="tel"
                    maxlength="10"
                    @change="isEmptyTelefono"
                  />
                </div>
              </div>

              <div class="form-group row justify-content-center">
                <div class="col-md-10 col-12">
                  <input
                    v-model="mailCamp"
                    type="email"
                    class="form-control p-3 border"
                    placeholder="Correo electrónico corporativo"
                    name="correo"
                    maxlength="50"
                    @change="isEmptyEmail"
                  />
                </div>
              </div>

              <div class="form-group row justify-content-center">
                <div class="col-md-10 col-12">
                  <input
                    v-model="clave1"
                    type="password"
                    class="form-control p-3 border"
                    placeholder="Contraseña"
                    name="key1"
                    maxlength="15"
                    @change="isEmptyPass1"
                  />
                </div>
              </div>

              <div class="form-group row justify-content-center">
                <div class="col-md-10 col-12">
                  <input
                    v-model="clave2"
                    type="password"
                    class="form-control p-3 border"
                    placeholder="Confirmar Contraseña"
                    name="key2"
                    maxlength="15"
                    @change="isEmptyPass2"
                  />
                </div>
              </div>

              <div class="form-group row justify-content-center">
                <div class="col-md-10 col-12">
                  <div @click='viewInputCodigo' style="cursor:pointer">
                    <label for="" class="font-13 color-080 mb-0">¿Tienes un código promocional?</label>
                    <i class="ml-2 fa fa-chevron-down color-080 font-10" v-if='!tieneCodigo'></i>
                    <i class="ml-2 fa fa-chevron-up color-080 font-10" v-if='tieneCodigo'></i>
                  </div>

                  <template v-if='tieneCodigo'>
                    <input
                      type="text"
                      v-model="codigo"
                      class="form-control p-3 border mt-2"
                      placeholder="Código promocional"
                      style="width: 245px;"
                    />
                  </template>
                </div>
              </div>

              <!-- <div class="form-group row justify-content-center">
                <div class="col-md-10 col-12" style="padding-right: 0;">
                  <label class="nunito">
                    <a :href="url_whatsapp" @click='eventClickAlert' target="_blank" rel="noopener noreferrer" class="autorizar gris2" style="text-decoration: underline;"><i class="fa fa-whatsapp" style='color: #1AD03F;font-size: 23px;position: relative;top: 3px;margin-right: 2px !important;'></i>Autoriza el envío de notificaciones a través de WhatsApp</a>
                  </label>
                </div> 
              </div> -->

              <div class="form-group row justify-content-center"> 
                <div class="col-md-10 col-12">
                  <label class="font-13 color-080 mb-0">
                    <input type="checkbox" v-model="terminos" name="termi" />
                    He leído y acepto los
                    <a :href="url_terminos" target="_blank">
                      <strong class="text-terminos font-13">Términos y Condiciones</strong>
                    </a>
                    y la <a :href="url_politica" target="_blank"><strong class="text-terminos font-13">Política de Tratamiento de Datos.</strong></a>
                  </label>
                </div>
              </div>
              <div class="form-group row justify-content-center mt-4 mb-1">
                <div class="col-md-10 col-12">
                  <div class="text-center">
                    <input
                      @click="crearCuenta"
                      type="button"
                      value="Crear Cuenta"
                      class="btn btn-secundario"
                      style="background-color: #FE3EB2 !important;"
                    /> 
                  </div>
                </div>
              </div>
              <!-- <div class="form-group">
                <div class="text-center mt-4">
                  <label class="parrafo-register">
                    Si eres usuario,
                    <a href="https://app.cellvoz.com/" class="text-info">
                      <strong>ingresa aquí</strong>
                    </a>
                  </label>
                </div>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import { fireToast } from '../assets/toast';
import swal from 'sweetalert2';

import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import Fuse from "fuse.js";

Vue.component('v-select', vSelect)

export default {
  components: {},
  data() {
    return {
      clientIp: 0,
      nombre: '',
      telefono: '',
      mailCamp: '',
      clave1: '',
      clave2: '',
      correo: '',
      codigo: '',
      terminos: false,
      nom: '',
      tel: 0,
      key1: '',
      key2: '',
      termi: '',
      url_terminos: '',
      url_politica: '',
      pais: { id: 41, title: "Colombia", iso: "co", cod_pais_wa: "+57", cod_pais: "57" },
      listaPaises: [],  
      tieneCodigo: false,
      url_whatsapp: ''  
    };
  },

  mounted() {
    this.publicIp();
    //this.flistaPaises();
    this.url_terminos = process.env.VUE_APP_URL_TERMINOS;
    this.url_politica = process.env.VUE_APP_URL_POLITICA;
    this.url_whatsapp = process.env.VUE_APP_URL_WHATSAPP;
  },

  methods: {
    formatFlag(texto) {
      let res = texto.toLowerCase();      
      res = res.replace(" / ", "-");
      res = res.replace("/", "-");
      res = res.replace(" ", "-");
      res = res.replace(" ", "-");
      res = res.replace(" ", "-");
      res = res.replace(" ", "-");
      res = res.replace("á", "a");
      res = res.replace("é", "e");
      res = res.replace("í", "i");
      res = res.replace("ó", "o");
      res = res.replace("ú", "u");
      res = res.replace("ú", "u");
      res = res.replace("ñ", "n");      
      return res;
    },
    flistaPaises() { 
      let alert = swal.fire({
        title: 'Cargando lista de paises...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      axios
        .get(process.env.VUE_APP_URL_API+'/lista_paises', {})
        .then((res) => {          
          this.listaPaises = res.data.data;
          alert.close();          
        })
        .catch((err) => {
          alert.close();
          console.log(err);
          swal.fire({
            title: 'Ha ocurrido un error',
            text: 'Ha ocurrido al obtener la lista de paises.',
            icon: 'danger',
            onOpen: () => {},
          });
        });

      return true;
    },
    crearCuenta() {  

      if (!this.isEmptyNameContact()) {
        return false;
      }

      if (!this.isEmptyTelefono()) {
        return false;
      }

      if (!this.isEmptyEmail()) {
        return false;
      }

      if (!this.isEmptyPass1()) {
        return false;
      }

      if (!this.isEmptyPass2()) {
        return false;
      }    

      if (!this.isEmptyTerminos()) {
        return false;
      }

      if (this.codigo == '' || this.codigo == ' ') {
        this.codigo = 31;
      }

      /*if (!this.terminos) {
        return false;
      }*/

      let alert = swal.fire({
        title: '<div class="nunito color-844 font-30">Estamos realizando el proceso de registro...</div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });     

      axios
        .post(process.env.VUE_APP_URL_REGISTER, {
          nombres: this.nombre,
          email: this.mailCamp,
          telefono: this.telefono,
          clave: this.clave1,
          ipaddress: this.clientIp,
          promocode: this.codigo,
          pais: this.pais.cod_pais,
          indicativo: this.pais.cod_pais
        })
        .then((res) => {
          //console.log(res.data);
          alert.close();
          swal.fire({
            title: '<div class="nunito color-844 font-30">Revisa tu celular</div>',
            text: 'Te hemos enviado un código de verificación por SMS para activar tu cuenta',
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(() => {            
            this.$router.push({path:'/validar', query:{ pk_token: res.data.token }});            
          });
        })
        .catch((err) => {
          alert.close();
          console.log(err);
          swal.fire({
            title: 'Ha ocurrido un error',
            text: 'Ha ocurrido un error al realizar el envio de su correo de registro.',
            icon: 'danger',
            onOpen: () => {},
          });
        });
      this.clearData();
    },

    clearData() {
      this.nombre = '';
      this.pais = '';
      this.mailCamp = '';
      this.telefono = '';
      this.clave1 = '';
      this.clave2 = '';
      this.terminos = false;
      this.codigo = '';
    },

    publicIp() {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((response) => {
          this.clientIp = response.ip;
          console.log(this.clientIp);
          fireToast('warning', 'Obtenemos su dirección IP .', 'timer:2000');
        });
    },

    isEmptyNameContact() {
      if (this.nombre == '') {
        fireToast(
          'error',
          'Debes ingresar un nombre de contacto',
          'timer:5000'
        );
        return false;
      }
      if (this.nombre.length < 3) {
        fireToast(
          'error',
          'El nombre de contacto no puede ser menor de tres digitos',
          'timer:5000'
        );
        return false;
      }
      return true;
    },
    isEmptyPais() {
      if (this.pais == null) {
        fireToast(
          'error',
          'El país no puede quedar vacio',
          'timer:5000'
        );
        return false;
      }      
      return true;
    },
    isEmptyTelefono() {
      if (this.telefono == '') {
        fireToast('error', 'Debes ingresar un número de celular', 'timer:5000');
        return false;
      }
      if (this.telefono == 0) {
        this.telefono = '';
        fireToast('error', 'El número de celular no puden ser ceros', 'timer:5000');
        return false;
      }
      if (this.telefono.length != 10) {
        fireToast(
          'error',
          'El número de celular digitado es inválido',
          'timer:5000'
        );
        return false;
      }
      if (this.telefono[0] != 3) {
        fireToast(
          'error',
          'El número de celular digitado no es de Colombia',
          'timer:5000'
        );
        return false;
      }

      let alert = swal.fire({
        title: '<div class="nunito color-844">Verificando número de celular</div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      axios
        .post(process.env.VUE_APP_URL_API+'/validated_number', {
          telefono: this.telefono,
        })
        .then((res) => {
          alert.close();
          if (res.data.status) {
            fireToast(
              'error',
              'El número de celular ha sido registrado anteriormente',
              'timer:5000'
            );
            this.telefono = '';
          }
        })
        .catch((err) => {
          alert.close();
          console.log(err);
        });
      return true;
    },
    isEmptyEmail() {
      let expMail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,10})+$/;
      if (this.mailCamp == '') {
        fireToast(
          'error',
          'El campo correo electrónico no puede ir vacío',
          'timer:5000'
        );
        return false;
      }

      if (!expMail.test(this.mailCamp)) {
        fireToast(
          'error',
          'Verifica el formato del correo electrónico.',
          'timer:5000'
        );
        return false;
      }
      let alert = swal.fire({
        title: '<div class="nunito color-844">Verificando correo electrónico</div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      axios
        .post(process.env.VUE_APP_URL_FIND, {
          email: this.mailCamp,
        })
        .then((res) => {
          alert.close();
          if (res.data.status) {
            fireToast(
              'error',
              'El correo electrónico ha sido registrado anteriormente',
              'timer:5000'
            );
            this.mailCamp = '';
          }
        })
        .catch((err) => {
          alert.close();
          console.log(err);
        });
      return true;
    },
    isEmptyPass1() {
      let espacios = false;
      let cont = 0;
      let valExp = /^(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ]/;
      if (this.clave1 == '') {
        fireToast(
          'error',
          'El campo contraseña no puede ir vacío',
          'timer:5000'
        );
        return false;
      }
      if (this.clave1.length < 8) {
        fireToast(
          'error',
          'La contraseña no puede tener menos de 8 caracteres',
          'timer:5000'
        );
        return false;
      }
      while (!espacios && cont < this.clave1.length) {
        if (this.clave1.charAt(cont) == ' ') espacios = true;
        cont++;
      }
      if (espacios) {
        this.clave1 = '';
        fireToast(
          'error',
          'El campo contraseña no puede tener espacios en blanco',
          'timer:5000'
        );
        return false;
      }
      if (!valExp.test(this.clave1)) {
        this.clave1 = '';
        fireToast(
          'error',
          'La contraseña debe contener caracteres alfanúmericos y al menos una letra mayúscula',
          'timer:5000'
        );
        return false;
      }
      return true;
    },
    isEmptyPass2() {
      let espacios = false;
      let cont = 0;
      let valExp = /^(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ]/;
      if (this.clave2 == '') {
        fireToast(
          'error',
          'Debes ingresar una contraseña',
          'timer:5000'
        );
        return false;
      }
      if (this.clave2.length < 8) {
        fireToast(
          'error',
          'El campo contraseña no puede tener menos de 8 caracteres',
          'timer:5000'
        );
        return false;
      }
      while (!espacios && cont < this.clave2.length) {
        if (this.clave2.charAt(cont) == ' ') espacios = true;
        cont++;
      }
      if (espacios) {
        this.clave2 = '';
        fireToast(
          'error',
          'El campo contraseña no puede tener espacios en blanco',
          'timer:5000'
        );
        return false;
      }
      if (!valExp.test(this.clave2)) {
        this.clave2 = '';
        fireToast(
          'error',
          'El campo contraseña debe tener caracteres alfanuméricos y por lo menos una letra mayúscula',
          'timer:5000'
        );
        return false;
      }

      if (this.clave1 != this.clave2) {
        this.clave1 = '';
        this.clave2 = '';
        fireToast('error', 'Las contraseñas no coinciden', 'timer:5000');
        return false;
      }
      return true;
    },
    isEmptyTerminos() {
      if (this.terminos == false) {
        fireToast('error', 'Debes aceptar los Términos y Condiciones y la Política de Tratamiento de Datos', 'timer:5000');
        return false;
      }     
      return true;
    },
    viewInputCodigo() {
      this.tieneCodigo = !this.tieneCodigo;        
    },
    eventClickAlert() {
      swal.fire({
        title: '<div class="nunito color-844">Se ha abierto una nueva ventana para autorizar las notificaciones a través de WhatsApp, al finalizar regresa aquí para continuar con la creación de tu cuenta.<br/><div class="spinner-border mt-3 color-4d3 " role="status" style="width: 3rem; height: 3rem;"><span class="color-4d3 sr-only">Cargando...</span></div></div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton:false,
        timer: 14000,
        width: '600px'
      });
      /*setTimeout(()=>{      
        this.$refs.Btn.click();
      },5600)*/
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["title"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    getLabel: function(val){
      console.log(val)
      /*if(typeof val === 'object'){
          return val.first_name;
      }else {
      return this.options.filter(function(option){
              return option.ip_address === val;
          })[0].first_name;
      }*/
      return '<img src="/images/logo-vertical.svg" alt="" width="50" height="50" style="display: block; margin: auto;">'
    }
  },
};
</script>
