import Swal from 'sweetalert2';

export function fireToast(icon, title){

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })

    Toast.fire({
        icon,
        title
    })

}




